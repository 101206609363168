a {
    font-weight: $font__weight--bold;
    text-decoration: underline;
    text-decoration-thickness: .08em;
    color: $color__link--hover;
    border-radius: 4px;
    outline: 0;
    transition: color .2s linear, outline .15s ease-in-out;


    &:hover {
        text-decoration: none;
        color: $color__link;
    }

    &:focus-visible {
        color: $color__link--hover;
        // box-shadow: 0 0 0 4px $color__primary--disabled;
        outline: 4px solid $color__primary--disabled;
        // outline-offset: 0;
    }
 
    // &.button {
    // } 

    &.arrow {
        display: inline-block;
        padding-right: #{$font__line-height}em;
        line-height: $font__line-height;

        &:hover,
        &:focus {
            &:after {
                background-position: calc(#{($font__line-height - .8) * .5}em + 4px) #{($font__line-height - .8) * .5}em;
                filter: $filter__primary;
            }
        }


        &::after {
            content: "";
            display: inline-block;
            position: relative;
            margin-right: -#{$font__line-height}em;
            // margin-left: 8px;
            width: #{$font__line-height}em;
            height: #{$font__line-height}em;
            vertical-align: top;
            background: {
                color: transparent;
                image: url(/bundles/hitcomfrontend/img/material-design-icons/outlined/arrow_forward_ios.svg);
                position: #{($font__line-height - .8) * .5}em #{($font__line-height - .8) * .5}em;
                repeat: no-repeat;
                size: .8em .8em;
            }
            filter: $filter__primary--hover; 
            transition: background-position .2s ease-in-out, filter .1s linear;
        }
    }
}

@media print {
    a:after {
        display: none;
        content: "";
    }
}
