.page__footer{
    overflow-x: hidden;
    .bg-black{
        background-color: #000000!important;
        position: relative;
        &:after{
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            background-image: url('/assets/website/img/eber_half.svg');
            filter: invert(7%) sepia(32%) saturate(5426%) hue-rotate(339deg) brightness(98%) contrast(100%);
            height: 95%;
            width: auto;
            aspect-ratio: 16/9;
            background-repeat: no-repeat;
            background-position: right bottom;
            pointer-events: none;
            z-index: 0;
        }
        .container-xxl{
            z-index: 1;
            position: relative;
        }
    }
    // .top-link button,
    .meta-navigation--social .meta-navigation__link{
        &.button{
            background-color: $color__white;
            padding: 7px;
            border-radius: 4px;
            img{
                 filter: $filter__black;
                 height:36px;
            }
            @include hover-focus-visible{
                background-color: $color__primary;
                img{
                    filter: $filter__white
                }
            }
        }
    }
    .button-invert {
        border: 1px solid $color__white;
        border-radius: 4px;
    }
    p{
        color: $color__white;
        >a:not(.button){
            color: $color__white;
            font-size: 22px;
        }
        &.button{
            font-size: 17px;
        }
    }
    .top-link button.button{
        padding: 17px 14px;
        background-color: $color__primary;
        img{
            filter: $filter__white;
            height: 16px;
            transform: rotate(-90deg);
        }
        @include hover-focus-visible{
            background-color: $color__black;
        }
    }
    .heading{
        position: relative;
        @media(max-width: 450px){
            margin-bottom: 0 !important;
        }
        &:before{
            content: "";
            position: absolute;
            left: -66.666666666%;
            width: 66.66666666%;
            background-color: $color__white;
            height: 1px;
            bottom: 12px;
        }
        h2{
            color: $color__white;
        }
    }
    .meta-navigation--accessibility{
        .meta-navigation__item{
            margin-bottom: 1px;
            a{
                padding: 12px  24px;
                border-radius: 4px;
                background-color: $color__white;
                color: $color__black;
                width: 100%;
                .icon{
                    img{
                        filter: $filter__black
                    }
                }
                @include hover-focus-visible{
                    background-color: $color__primary;
                    color: $color__white;
                    .icon img{
                        filter: $filter__white
                    }
                }
            }
        }
    }
    .bottom-white{
        position: relative;
        border: none;
        &:before{
            content: "";
            position: absolute;
            left: -10px;
                right:-10px;
            top: 1px;
            height: auto;
            aspect-ratio: 1920/60;
            background-image: url(/assets/website/img/welle.svg);
            background-repeat: no-repeat;
            background-size: cover;
            transform: translateY(-100%);
        }
    }
    .meta-navigation--legal .meta-navigation__button{
        color: $color__black;
    }
    .meta-navigation--legal .meta-navigation__item:not(:last-child){
        margin-right: 15px;
        padding-right: 15px;
        border-right: 1px solid $color__black;
    }
    .meta-navigation--legal{
        @include media-breakpoint-down(lg) {
            padding-top: 20px;
        }
    }
    .meta-navigation--social{
        @media(max-width:450px){
            margin-bottom: 3rem;
        }
    }
}
.page__footer .top-link.top-link--sticky button{

}
.page__footer .top-link:not(.top-link--sticky) button{
    position: relative;
    display: inline-block;
    z-index: 98
}