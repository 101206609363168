body[data-template="home"]{
    .splide__arrow[data-action=start], .splide__arrow[data-action=stop]{
        margin-right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        span:not(.tns-visually-hidden){
            width: 30px;
            height: 30px;
            img{
                height: 30px;
            }
        }
        .icon{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .page__header{
        .banner--slider{
            z-index: 101;
        }
        >.container-fluid {
            .home-logo{
                display: none
            }
        }
    }
    .splide__arrow[data-controls=prev]{
        margin-right: 0;
    }
    .splide--init .splide__footer .splide__navigation{
        position: relative;
        left: unset;
        top: unset;
        transform: translate(0);
        justify-content: flex-end;
    }
    .banner{
        background-color: $color__white;
    }
    .banner:after{
        bottom: 79px;
    }
    .headroom--above-the-fold:not(.headroom--near-the-fold) &{
        .page__header{
            .banner--slider{
                z-index: 1;
            }
            .home-logo{
                display: block;
                width: 150px;
                height: 165px;
                background-color: $color__white;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                position: absolute;
                left: 50%;
                top: 0;
                transform: translateX(-50%);
                padding: 15px;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
                @media(max-width: 400px){
                    width: 105px;
                    height: 117px;
                    padding: 5px;
                }
            }
            .search{
                margin-top: 30px;
            }
            .logo{
                display: none;
            }
            &:before{
                display: none;
            }
            >.container-fluid{
                justify-content: flex-end;
                position: absolute;
            }
        }
    }
}
