.section {
    &.section--margin-top {
        margin-top: 120px;
    }

    &.section--margin-bottom {
        margin-bottom: 120px;
    }

    &.section--padding-top {
        padding-top: 64px;

        @include media-breakpoint-up(lg) {
            padding-top: 120px;
        }
    }

    &.section--padding-bottom {
        padding-bottom: 64px;

        @include media-breakpoint-up(lg) {
            padding-bottom: 120px;
        }
    }

    &.section--bg-light {
        background-color: $color__gray--light;

        .page__main > & {
            &:last-of-type {
                body:not([data-editmode='true']) & {
                    border-bottom: 1px solid $color__gray;
                }
            }
        }

        body[data-template='home'].banner-fullscreen .page__main > & {
            &:first-of-type {
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: -80px;
                    left: 0;
                    width: 100%;
                    height: 80px;
                    background-color: $color__gray--light;
                    pointer-events: none;
                }
            }
        }
    }

    &.section--bg-dark {
        background-color: $color__gray--light;
        position: relative;
        &:before{
            content: "";
            aspect-ratio: 536/460;
            width: auto;
            height: 460px;
            background-image: url('/assets/website/img/eber_half.svg');
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            right: 0;
            bottom: 0;
            filter: invert(99%) sepia(1%) saturate(4413%) hue-rotate(284deg) brightness(119%) contrast(59%);
            max-width: 560px;
            background-position: center bottom;
            max-height: 90%;
            @media(max-width: 600px){
                max-height: 50%;
            }
        }
        >div{
            z-index: 1;
            position: relative;
        }
    }

    &.section--bg-image {
        position: relative;
        z-index: 0;
    }

    &__background-image {
        position: absolute;
        z-index: -1;
        top: 0; left: 0;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    body[data-template='home'] .page__main > & {
        header {
            margin-bottom: 48px;
        }
    }
}
